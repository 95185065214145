<template>
  <div>
    <custom-breadcrumb
      pageTitle="Partner"
      :breadcrumb="[
        {
          text: 'Partners',
          to: 'partners'
        },
        {
          text: $t('Detail'),
          active: true,
        },
      ]"
  ></custom-breadcrumb>
    <template v-if="partner">
      <b-row>
        <b-col
          cols="12"
          lg="6"
        >  
          <info-card 
            :id="partner.id"
            :avatar_src="partner.logo_url"
            :title="partner.description"
            :subtitle="partner.support_email"
            :showEdit="true"
            :showDelete="false"
            :showRecover="false"
            :items="info_card_items"
            ability="Partners"
            link="partners"

            @refetch-data="getData(partner.id)"
          />
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >  
          <b-card
            no-body
            class="card-employee-task"
          >
            <b-card-header>
              <b-card-title>{{$t('dash.statistics.active_admin_licences')}}</b-card-title>
              <b-button variant="link" class="pointer p-0"
                  :to="{ 
                    name: 'partners-edit', 
                    params: { 
                      id: partner.id, 
                      tab:1
                    } 
                  }">
                <feather-icon
                  icon="PlusIcon"
                  size="18"
                  class="cursor-pointer"
                />
              {{ $t('forms.add_to', {model1: $t('models.admin_licence'), model2: $t('models.partner')})}}
              </b-button>
            </b-card-header>

            <!-- body -->
            <b-card-body>
              <template v-for="user in linked_users">
              <div
                :key="user.id"
                class="employee-task d-flex justify-content-between align-items-center"
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar
                      size="42"
                      :text="avatarText(user.name)"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="mb-0">
                      <b-link
                        class="alert-link"
                        :to="{ name: 'licences-view', params: { id: user.id } }"
                      >
                      {{ user.name }}
                      </b-link>
                    </h6>
                    <!-- <small class="text-muted ">{{ dreamer.age }} years old</small> -->
                  </b-media-body>
                </b-media>
                <div class="d-flex align-items-center pointer" 
                  @click="removeUser(user.id)"
                >
                  <small class="m-75" style="color:tomato;">
                    <font-awesome-icon :icon="['fas', 'trash-alt']"
                    size="lg" />
                  </small>
                </div>
              </div>
              </template>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >  
        <b-card
            no-body
          >
        <paginated-table
          :data="schools"
          ability="Schools"
          link='school'
          :canDownload="false"
          :canSelect="false"
          @delete="removeSchool"
          :loading_data="loading_data"

        ></paginated-table>
        </b-card>
          <!-- <b-card
            no-body
            class="card-employee-task"
          >
            <b-card-header>
              <b-card-title>{{$t('Schools')}}</b-card-title>
              <b-button variant="link" class="pointer p-0"
                  :to="{ 
                    name: 'partners-edit', 
                    params: { 
                      id: partner.id, 
                      tab:2
                    } 
                  }">
                <feather-icon
                  icon="PlusIcon"
                  size="18"
                  class="cursor-pointer"
                />
              {{ $t('forms.add_to', {model1: $t('models.school'), model2: $t('models.partner')})}}
              </b-button>
            </b-card-header>

            <b-card-body>
              <template v-for="school in schools">
              <div
                v-if="!school.deletd_at"
                :key="school.id"
                class="employee-task d-flex justify-content-between align-items-center"
              >
                <b-media no-body>
                  <b-media-aside class="mr-75">
                    <b-avatar
                      size="42"
                      :text="avatarText(school.name)"
                      :src="school.logo_url"
                      variant="light"
                    />
                  </b-media-aside>
                  <b-media-body class="my-auto">
                    <h6 class="mb-0">
                      <b-link
                        class="alert-link"
                        :to="{ name: 'school-view', params: { id: school.id } }"

                      >
                        {{ school.name }}
                      </b-link>
                      
                    </h6>
                  </b-media-body>
                </b-media>
                <div class="d-flex align-items-center pointer" 
                  @click="removeSchool(school.id)"
                >
                  <small class="m-75" style="color:tomato;">
                    <font-awesome-icon :icon="['fas', 'trash-alt']"
                    size="lg" />
                  </small>
                </div>
              </div>
              </template>
            </b-card-body>
          </b-card> -->
        </b-col>
      </b-row>
    </template>
    <template v-else>
      <b-alert
        variant="danger"
        :show="partner === undefined"
      >
        <h4 class="alert-heading">
          Error fetching data
        </h4>
        <div class="alert-body">
          No data found with this id. Go back to
          <b-link
            class="alert-link"
            :to="{ name: 'partners'}"
          >
            Partners List
          </b-link>
        </div>
      </b-alert>
    </template>
 </div>
</template>

<script>
import { detachLicenceFromPartner, deleteSchool, fetchPartner } from '@/api/routes'

import { avatarText } from '@core/utils/filter'
import CustomBreadcrumb from '@/views/components/breadcrumb/CustomBreadcrumb.vue'
import PaginatedTable from '@/views/sl-components/PaginatedTable.vue'

import router from '@/router'
import InfoCard from '@/views/sl-components/InfoCard.vue'
import {
  BRow, BCol, BButton,
    BCard, BCardTitle, BCardHeader, BCardBody, BMedia, 
    BMediaAside, BAvatar, BMediaBody, BAlert, BLink,
} from 'bootstrap-vue'
export default {
  components:{
    InfoCard,
    PaginatedTable,
    CustomBreadcrumb,
    BRow, BCol, BButton,
    BCard, BCardTitle, BCardHeader, BCardBody, BMedia, 
    BMediaAside, BAvatar, BMediaBody, BAlert, BLink,
  },
  setup(){
      return {
          avatarText
      }
  },
  data(){
    return {
      id: null,
      partner: null,
      schools:[], 
      linked_users:[],
      loading_data:true,
      languages:[]
    }
  },
  computed:{
    info_card_items(){
      let items = [
      {
          icon: "SmileIcon",
          text: this.$t('dash.cards.total_licenses'),
          value: this.partner.total_licenses
        },
        {
          icon: "UsersIcon",
          text: this.$t('dash.cards.active_licenses'),
          value: this.partner.active_licenses
        },
        {
          icon: "UserXIcon",
          text: this.$t('dash.cards.inactive_licenses'),
          value: this.partner.inactive_licenses
        },
        {
          icon: "UsersIcon",
          text: this.$t('dash.cards.student_licenses'),
          value: this.partner.student_licenses
        },
        {
          icon: "UserIcon",
          text: this.$t('dash.cards.teacher_licenses'),
          value: this.partner.teacher_licenses
        },
        {
          icon: "Trash2Icon",
          text: this.$t('dash.cards.deleted_licenses'), 
          value: this.partner.deleted_licenses
        },
        {}, // Para dejar un espacio en blanco
        {
          icon: "UsersIcon",
          text: this.$t('max_children'),
          value: this.partner.users_defaults ? this.partner.users_defaults.max_children : ''
        },
        {
          icon: "LinkIcon",
          text: "Bundles url",
          value: this.partner.bundles_url
        },
        {
          icon: "LinkIcon",
          text: "Bundles url legacy",
          value: this.partner.bundles_url_legacy
        },
        {
          icon: "KeyIcon",
          text: "API Token",
          value: this.partner.api_token
        },
        {
          icon: "GlobeIcon",
          text: this.$t('language'),
          value: this.partner.users_defaults && this.partner.users_defaults.lang_id ? this.resolveLang(this.partner.users_defaults.lang_id) : ''
        },
        {
          icon: "MailIcon",
          text: "Emailing",
          value: this.partner.users_defaults && this.partner.users_defaults.emailing ? this.$t('Yes') : this.$t('No')
        },
        {
          icon: "GiftIcon",
          text: "Welcomed",
          value: this.partner.users_defaults && this.partner.users_defaults.welcomed ? this.$t('Yes') : this.$t('No')
        },
        {
          icon: "InboxIcon",
          text: "Zero Emailing",
          value: this.partner.users_defaults && this.partner.users_defaults.zero_emailing ? this.$t('Yes') : this.$t('No')
        },
        {
          icon: "LayoutIcon",
          text: this.$t('fields.access_web'),
          value: this.partner.users_defaults && this.partner.users_defaults.allowed_access_la ? this.$t('Yes') : this.$t('No')
        },
        {
          icon: "ZapIcon",
          text: this.$t('fields.can_edit'),
          value: this.partner.canEdit ? this.$t('Yes') : this.$t('No')
        },
      ]
      return items
    }
  },
  methods: {
    resolveLang(id){
      let lang = null
      this.languages.map(l => {
        if(l.id == id){
          lang=l
        }
      })
      return this.$t('lang.'+lang.name)
    },
    makeToast(variant = null, tit ='',msg = '') {
      this.$bvToast.toast(msg, {
        title: tit,
        variant,
        solid: false,
      })
    },
    removeUser(id){
      //eliminar school
      // console.log(id, "user_id")

       this.$bvModal
        .msgBoxConfirm(this.$t('table.delete_one_confirmation')+' '+this.$t('delete_structure.disclaimer'), {
          title: this.$t('forms.delete_model', {model:this.$t('models.licence')}),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'user_id': id
            }
            this.$http.post(detachLicenceFromPartner, data).then( response =>{
              // console.log(response)
              if(response.data.status == 200){
                this.getData(this.id)

                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));
                this.closeForm()
              } else {
                // console.log(response.data, "NO SE PUEDE ELIMINAR");
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    

    },
    removeSchool(id){
      //eliminar school
      // console.log(id, "school_id")

      this.$bvModal
        .msgBoxConfirm(this.$t('table.delete_one_confirmation'), {
          title: this.$t('forms.delete_model', {model:this.$t('models.school')}),
          size: 'sm',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if(value){
            let data = {
              'school_id': id
            }
            this.$http.post(deleteSchool, data).then( response =>{
              // console.log(response)
              if(response.data.status == 200){
                this.getData(this.id)

                this.makeToast('success', this.$t('Success'), this.$t('table.delete_success'));
                this.closeForm()
              } else {
                // console.log(response.data, "NO SE PUEDE ELIMINAR");
                this.makeToast('danger', this.$t('Error'), this.$t('table.delete_error'));
              }

            }).catch(error => {
              // console.log("Err -> ", error)
            })
          }
        })
    
    },
    async getData(id){
      this.loading_data = true
        let data = {
          'id': id,
        }
        await this.$http.post(fetchPartner, data).then( response =>{
            console.log("fetchPartner", response)
            this.languages = response.data.languages;
            this.partner = response.data.partner;
            this.schools = response.data.schools;
            this.linked_users = response.data.linked_users;
            
            this.loading_data = false

        }).catch(error => {
          // console.log("Err -> ", error);
          this.loading_data = false

          this.makeToast('danger', this.$t('Error'),this.$t('error_retry_later'));
        })
    },
  },
  mounted(){
    this.id = router.currentRoute.params.id;
    this.getData(this.id)
  },
}
</script>

<style>

</style>