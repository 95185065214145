var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('custom-breadcrumb',{attrs:{"pageTitle":"Partner","breadcrumb":[
       {
         text: 'Partners',
         to: 'partners'
       },
       {
         text: _vm.$t('Detail'),
         active: true,
       } ]}}),(_vm.partner)?[_c('b-row',[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('info-card',{attrs:{"id":_vm.partner.id,"avatar_src":_vm.partner.logo_url,"title":_vm.partner.description,"subtitle":_vm.partner.support_email,"showEdit":true,"showDelete":false,"showRecover":false,"items":_vm.info_card_items,"ability":"Partners","link":"partners"},on:{"refetch-data":function($event){return _vm.getData(_vm.partner.id)}}})],1),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('b-card',{staticClass:"card-employee-task",attrs:{"no-body":""}},[_c('b-card-header',[_c('b-card-title',[_vm._v(_vm._s(_vm.$t('dash.statistics.active_admin_licences')))]),_c('b-button',{staticClass:"pointer p-0",attrs:{"variant":"link","to":{ 
                   name: 'partners-edit', 
                   params: { 
                     id: _vm.partner.id, 
                     tab:1
                   } 
                 }}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":"PlusIcon","size":"18"}}),_vm._v(" "+_vm._s(_vm.$t('forms.add_to', {model1: _vm.$t('models.admin_licence'), model2: _vm.$t('models.partner')}))+" ")],1)],1),_c('b-card-body',[_vm._l((_vm.linked_users),function(user){return [_c('div',{key:user.id,staticClass:"employee-task d-flex justify-content-between align-items-center"},[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-75"},[_c('b-avatar',{attrs:{"size":"42","text":_vm.avatarText(user.name)}})],1),_c('b-media-body',{staticClass:"my-auto"},[_c('h6',{staticClass:"mb-0"},[_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'licences-view', params: { id: user.id } }}},[_vm._v(" "+_vm._s(user.name)+" ")])],1)])],1),_c('div',{staticClass:"d-flex align-items-center pointer",on:{"click":function($event){return _vm.removeUser(user.id)}}},[_c('small',{staticClass:"m-75",staticStyle:{"color":"tomato"}},[_c('font-awesome-icon',{attrs:{"icon":['fas', 'trash-alt'],"size":"lg"}})],1)])],1)]})],2)],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-card',{attrs:{"no-body":""}},[_c('paginated-table',{attrs:{"data":_vm.schools,"ability":"Schools","link":"school","canDownload":false,"canSelect":false,"loading_data":_vm.loading_data},on:{"delete":_vm.removeSchool}})],1)],1)],1)]:[_c('b-alert',{attrs:{"variant":"danger","show":_vm.partner === undefined}},[_c('h4',{staticClass:"alert-heading"},[_vm._v(" Error fetching data ")]),_c('div',{staticClass:"alert-body"},[_vm._v(" No data found with this id. Go back to "),_c('b-link',{staticClass:"alert-link",attrs:{"to":{ name: 'partners'}}},[_vm._v(" Partners List ")])],1)])]],2)}
var staticRenderFns = []

export { render, staticRenderFns }